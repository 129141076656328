import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Component/Home";
import Robotico from "./Component/Robotico";
import PricingPage from "./Component/PricingPage";
import SelfService from "./Component/Self-Service/SelfService";
import ContactUs from "./Component/ContactUs";
import FrothTech from "./Component/FrothTech";
import TermsAndConditions from "./Component/Terms";
import PrivacyPolicy from "./Component/PrivacyPolicy";
import Login from "./Component/Self-Service/Login";
import PageNotFound from "./Component/PageNotFound";
import Myaccount from "./Component/Myaccount";

const PageRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/RoboticoDigital" element={<Robotico />} />
      <Route path="/Pricing" element={<PricingPage />} />
      <Route path="/my-account" element={<Myaccount />} />
      <Route path="/Self-Service" element={<SelfService />} />
      <Route path="/ContactUs" element={<ContactUs />} />
      <Route path="/FrothTech" element={<FrothTech />} />
      <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/Login" element={<Login />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default PageRouter;
