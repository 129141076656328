const apiUrl = "https://rd.frothcrm.com/pool/public/api/"

const statusColors = {
    New: 'rgb(0, 156, 224)',
    Assigned:'rgb(48, 141, 0)',
    Open: 'rgb(126 82 227 / 67%)',
    Pending: 'rgb(191 82 82 / 82%)',
    Rejected: 'rgb(191 82 82 / 82%)',
    Cancelled: 'rgb(191 82 82 / 82%)',
    Resolved: 'rgb(91 182 110 / 65%)',
    Closed: 'rgb(91 182 110 / 65%)',
    Default: 'rgb(35 59 213)',
  };
  

export {
    apiUrl,statusColors
}